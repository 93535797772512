import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio
} from '@material-ui/core'
import { parseJson } from '../../../../components/formatters/parseJson'
import React, { Fragment, useState } from 'react'
import { TokenexASCIIDisplay } from '../../../tokenexASCIIDisplay'
import { TokenexSSNDisplay } from '../../../tokenexSSNDisplay'
import Activity from '../activity'
import Modal from '../modal'
import PackageInfo from '../packageInfo'
import UserInfo from '../userInfo'

const securityQuestionDictionary = {
  firstPet: 'What is the name of your first pet?',
  thirdGradeCity: 'What city did you lived on in 3rd grade?',
  firstGradeTeacher: 'What was the name of your first grade teacher?',
  cityParentsMet: 'What is the name of the city that your parents met?',
  grandmotherMaidenName: "What is your maternal grandmother's maiden name?",
  school: 'What school did you attend when you were 10 years old?',
  firstConcert: 'What was the first concert that you attended?'
}

/* Equipment */
const Equipment = ({ classes, record }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <h3>Choose your equipment</h3>
        {record.installation.internet_modem_choice === 'internetRental' && (
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={
                    record.installation.internet_modem_choice ===
                    'internetRental'
                  }
                  classes={{ disabled: classes.disabled }}
                />
              }
              label={`${record.price.items['internet_rental'].label} (${record.price.items['internet_rental'].price_description})`}
              labelPlacement="end"
              classes={{ label: classes.label }}
              disabled
            />
          </div>
        )}
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={
                  record.installation.internet_modem_choice ===
                  'internetModemOwned'
                }
                classes={{ disabled: classes.disabled }}
              />
            }
            label="I'll use my own"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </div>
      </CardContent>
    </Card>
  </Fragment>
)

/* Credit Check-SSN-Contact */
const CreditCheckInfo = ({ classes, ssnToken, dobToken, record }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Fragment>
      <h3>Credit Check</h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="I authorize a credit check"
          labelPlacement="end"
          classes={{ label: classes.label }}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <h3>SSN</h3>
      </div>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleOpen}>
          Show Full SSN Info
        </Button>
        <Modal open={open} onClose={handleClose} title="Full SSN Info">
          <div>
            <TokenexSSNDisplay token={ssnToken} record={record} />
          </div>
        </Modal>
      </div>
      <div style={{ marginTop: 50 }}>
        <h3>I have lived at my current address less than 6 months</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={false} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="No"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>
        <h3>Customer’s billing address</h3>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="Same as service address"
          labelPlacement="end"
          classes={{ label: classes.label }}
        />
      </div>
      <div style={{ marginTop: 50 }}>
        <p>Verification - Credit Results / Credit Options</p>
        <p>
          Contact consumer if they fail the credit results and will be required
          to pay a $50.00 deposit to go forward.
        </p>
      </div>
    </Fragment>
  )
}

/* PIN-Security-DOB */
const Security = ({ record, passcodeToken, securityAnswerToken, dobToken }) => {
  const [openSecurityAnswerModal, setOpenSecurityAnswerModal] = useState(false)
  const handleOpenSecurityAnswerModal = () => setOpenSecurityAnswerModal(true)
  const handleCloseSecurityAnswerModal = () => setOpenSecurityAnswerModal(false)
  const [openPasscodeModal, setOpenPasscodeModal] = useState(false)
  const handleOpenPasscodeModal = () => setOpenPasscodeModal(true)
  const handleClosePasscodeModal = () => setOpenPasscodeModal(false)
  const [openDobModal, setOpenDobModal] = useState(false)
  const handleOpenDobModal = () => setOpenDobModal(true)
  const handleCloseDobModal = () => setOpenDobModal(false)
  return (
    <Fragment>
      <Card style={{ marginTop: 20 }}>
        <CardContent>
          <h3>Create a Cox Pin</h3>
          <div style={{ marginTop: 20 }}>
            <Button variant="contained" onClick={handleOpenPasscodeModal}>
              Show Cox Pin
            </Button>
            <Modal
              open={openPasscodeModal}
              onClose={handleClosePasscodeModal}
              title="Passcode"
            >
              <div>
                <TokenexASCIIDisplay token={passcodeToken} record={record} />
              </div>
            </Modal>
          </div>

          <h3>Show pin on bill?</h3>
          <div>
            <p>Yes</p>
          </div>

          <h3>Date of Birth</h3>
          <div style={{ marginTop: 20 }}>
            <Button variant="contained" onClick={handleOpenDobModal}>
              Show Date of Birth
            </Button>
            <Modal
              open={openDobModal}
              onClose={handleCloseDobModal}
              title="Date of Birth"
            >
              <div>
                <TokenexASCIIDisplay token={dobToken} record={record} />
              </div>
            </Modal>
          </div>

          <h3>Security Question</h3>
          <div>
            <p>
              {
                securityQuestionDictionary[
                  record?.credit_check?.security_questions?.security_question
                ]
              }
            </p>
          </div>

          <h3>Security Answer</h3>
          <div style={{ marginTop: 20 }}>
            <Button variant="contained" onClick={handleOpenSecurityAnswerModal}>
              Show Security Answer
            </Button>
            <Modal
              open={openSecurityAnswerModal}
              onClose={handleCloseSecurityAnswerModal}
              title="Security Answer"
            >
              <div>
                <TokenexASCIIDisplay
                  token={securityAnswerToken}
                  record={record}
                />
              </div>
            </Modal>
          </div>
        </CardContent>
      </Card>
    </Fragment>
  )
}

/* Installation */
const Installation = ({ classes }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <h3>Installation Options</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Self Install - (Shipment) if not available defer to Self - Activation"
            labelPlacement="end"
            disabled
            classes={{ label: classes.label }}
          />
        </div>
      </CardContent>
    </Card>
  </Fragment>
)

/* Additional Questions */
const OtherQuestions = ({ classes }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <h3>Emergency Broadband Relief</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="No"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>Internet Terms and Conditions</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>Terms and Conditions of Service</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>1 Year Term Agreement</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>Easy Pay Agreement</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>Monthly Total Agreement</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>
      </CardContent>
    </Card>
  </Fragment>
)

const ShippingInformation = ({
  classes,
  record: {
    move: { to_address = {} } = {},
    contact_information: { new_shipping_address = {}, shipping_address }
  } = {}
}) => (
  <Fragment>
    <h3>Shipping Information</h3>
    {!shipping_address ? (
      <div>N/A</div>
    ) : (
      <>
        {shipping_address !== 'Somewhere else' ? (
          <div>{shipping_address}</div>
        ) : (
          <>
            <div>Street: {new_shipping_address.street}</div>
            <div>Apartment: {new_shipping_address.unit}</div>
            <div>City: {new_shipping_address.city}</div>
            <div>State: {new_shipping_address.state}</div>
            <div>Zip: {new_shipping_address.postal_code}</div>
          </>
        )}
      </>
    )}
  </Fragment>
)

const AdditionalOptions = ({ classes, record: { opt_ins = {} } = {} }) => (
  <Fragment>
    <h3>
      Allow Updater and Cox to reach you by phone or text if there’s a problem
      with my order?
    </h3>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={opt_ins.sms_opt_in}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="Yes, I consent"
        labelPlacement="end"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
    <h3>Contact me by</h3>
    <div>
      <FormControlLabel
        control={
          <Radio checked={true} classes={{ disabled: classes.disabled }} />
        }
        label="Email"
        labelPlacement="end"
        classes={{ label: classes.label }}
      />
    </div>
    <div>
      <FormControlLabel
        control={
          <Radio checked={false} classes={{ disabled: classes.disabled }} />
        }
        label="Phone"
        labelPlacement="end"
        classes={{ label: classes.label }}
      />
    </div>
  </Fragment>
)

/* Main constructed component */
export const OrderView = ({ record, classes }) => {
  const currentAddressIsOverSixMonths = Boolean(
    record.credit_check?.has_lived_at_current_address_for_more_than_6_months ==
      'Yes'
  )
  const priorAddress = record?.credit_check?.prior_address
  const ssnToken = parseJson(record?.credit_check?.ssn?.token)?.token
  const dobToken = parseJson(record?.credit_check?.date_of_birth?.token)?.token
  const securityAnswerToken = parseJson(
    record?.credit_check?.security_questions?.security_answer?.token
  )?.token
  const passcodeToken = parseJson(record?.credit_check?.passcode?.token)?.token
  return (
    <Fragment>
      <Grid item xs={6}>
        <UserInfo record={record} />
      </Grid>
      <Grid item xs={6}>
        <PackageInfo record={record} />
      </Grid>

      <Grid item xs={12}>
        <Equipment classes={classes} record={record} />
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardContent>
            <ShippingInformation classes={classes} record={record} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardContent>
            <CreditCheckInfo
              record={record}
              classes={classes}
              serviceAddress={record.move.to_address}
              ssnToken={ssnToken}
              dobToken={dobToken}
              currentAddressIsOverSixMonths={currentAddressIsOverSixMonths}
              priorAddress={priorAddress}
              passcodeToken={passcodeToken}
              securityAnswerToken={securityAnswerToken}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Security
          record={record}
          passcodeToken={passcodeToken}
          securityAnswerToken={securityAnswerToken}
          dobToken={dobToken}
        />
      </Grid>
      <Grid item xs={12}>
        <Installation classes={classes} />
      </Grid>
      <Grid item xs={12}>
        <AdditionalOptions classes={classes} record={record} />
      </Grid>
      <Grid item xs={12}>
        <OtherQuestions classes={classes} />
      </Grid>
      <Grid item xs={12}>
        <Activity record={record} classes={classes} />
      </Grid>
    </Fragment>
  )
}
