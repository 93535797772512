import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio
} from '@material-ui/core'
import { parseJson } from '../../../../components/formatters/parseJson'
import { NoPaymentMethodDisclaimer } from '../../../fragments/no-payment-method'
import React, { Fragment, useState } from 'react'
import { CreditCard } from '../../../creditCard'
import { TokenexASCIIDisplay } from '../../../tokenexASCIIDisplay'
import { TokenexSSNDisplay } from '../../../tokenexSSNDisplay'
import Activity from '../activity'
import Modal from '../modal'
import PackageInfo from '../packageInfo'
import UserInfo from '../userInfo'
import { DEPOSIT_AMOUNT } from './constants'

const CopyButton = ({ copyText }) => (
  <Button
    size="large"
    style={{
      marginLeft: '10px',
      width: '110px',
      verticalAlign: 'middle',
      paddingLeft: '5px',
      paddingBottom: '5px'
    }}
    color="primary"
    variant="contained"
    onClick={() => {
      navigator.clipboard.writeText(copyText)
    }}
  >
    ✂ Copy
  </Button>
)

const TransferService = ({ ssnToken, record }) => {
  const [openSsnModal, setOpenSsnModal] = useState(false)
  const handleOpenSsnModal = () => setOpenSsnModal(true)
  const handleCloseSsnModal = () => setOpenSsnModal(false)
  const fromAddress = record?.contact_information?.from_address
  const transferDates = record.installation?.service_transfer_dates

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Transfer existing service" />
        <CardContent>
          <Fragment>
            {ssnToken && (
              <div>
                <h3>Locate the customer&apos;s Xfinity account</h3>
                <Button variant="contained" onClick={handleOpenSsnModal}>
                  Last 4 of SSN
                </Button>
                <Modal
                  open={openSsnModal}
                  onClose={handleCloseSsnModal}
                  title="Last 4 of SSN"
                >
                  <div>
                    <TokenexSSNDisplay token={ssnToken} record={record} />
                  </div>
                </Modal>
              </div>
            )}
          </Fragment>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <div>
            <h3>Previous address</h3>
            {!fromAddress ? (
              <div>N/A</div>
            ) : (
              <>
                <div>
                  {fromAddress.street}
                  {fromAddress.unit && `, ${fromAddress.unit}`}
                </div>

                <div>
                  {fromAddress.city}, {fromAddress.state}{' '}
                  {fromAddress.postal_code}
                </div>
              </>
            )}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Fragment>
            <div style={{ marginTop: 10 }}>
              <h3>Disconnect on</h3>
              {new Date(transferDates?.disconnect_date).toLocaleDateString()}
              <CopyButton
                copyText={new Date(
                  transferDates?.disconnect_date
                ).toLocaleDateString()}
              />
            </div>
            <div>
              <h3>Reconnect on</h3>
              <div>
                {new Date(transferDates?.reconnect_date).toLocaleDateString()}
                <CopyButton
                  copyText={new Date(
                    transferDates?.reconnect_date
                  ).toLocaleDateString()}
                />
              </div>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                (Timeslot) 10AM - 12PM
              </div>
            </div>
          </Fragment>
        </CardContent>
      </Card>
    </Grid>
  )
}
const EquipmentOptions = ({ classes, record }) => {
  const { installation: { modem_choice = '', tv_equipment_choice = '' } = {} } =
    record

  const modemChoiceAsLowerCase = modem_choice?.toLowerCase()

  // Same as new `modem_choice.xfinityGateway`
  const hasOldInternetRentalAsChoice =
    modemChoiceAsLowerCase === 'internetRental'.toLocaleLowerCase()

  // Why toLowerCase? guest-buyflow & Consumer-app follow different casing.
  const isXFiGateway =
    hasOldInternetRentalAsChoice ||
    modemChoiceAsLowerCase === 'xfinityGateway'.toLowerCase()
  const isXFiComplete =
    modemChoiceAsLowerCase === 'xFiComplete'.toLocaleLowerCase()
  const isModemOwned =
    modemChoiceAsLowerCase === 'internetModemOwned'.toLocaleLowerCase()

  return (
    <Fragment>
      <h3>Modem Choice</h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={isXFiGateway}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="xFi Gateway ($15.00 / month)"
          disabled
          classes={{ label: classes.label }}
        />
        <p className={classes.optionSubtext}>
          Customer understands that the price may increase to up to $25/mo after
          the first 12-24 months
        </p>
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={isXFiComplete}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="xFi Complete ($5.00 / month)"
          disabled
          classes={{ label: classes.label }}
        />
        <p className={classes.optionSubtext}>
          Customer understands that the price may increase to up to $15/mo after
          the first 12-24 months
        </p>
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={isModemOwned}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="I'll use my own"
          disabled
          classes={{ label: classes.label }}
        />
      </div>

      {Boolean(tv_equipment_choice) && (
        <>
          <h3>TV Equipment Choice</h3>
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={tv_equipment_choice.toLowerCase() === 'x1-tv-box'}
                  classes={{ disabled: classes.disabled }}
                />
              }
              label="X1 TV Box ($12.00 / month)"
              disabled
              classes={{ label: classes.label }}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={
                    tv_equipment_choice.toLowerCase() === 'customer-owned'
                  }
                  classes={{ disabled: classes.disabled }}
                />
              }
              label="Customer-Owned Streaming Device (No Charge)"
              disabled
              classes={{ label: classes.label }}
            />
          </div>
        </>
      )}
    </Fragment>
  )
}

const IdentityVerification = ({ classes, dobToken, ssnToken, record }) => {
  const creditCheckMethod =
    record?.credit_check?.credit_check_method?.toLowerCase()

  const priorAddress = record?.credit_check?.prior_address

  const [openDobModal, setOpenDobModal] = useState(false)
  const handleOpenDobModal = () => setOpenDobModal(true)
  const handleCloseDobModal = () => setOpenDobModal(false)

  const [openSsnModal, setOpenSsnModal] = useState(false)
  const handleOpenSsnModal = () => setOpenSsnModal(true)
  const handleCloseSsnModal = () => setOpenSsnModal(false)

  return (
    <Fragment>
      <div>
        {priorAddress && (
          <>
            <h3>Move From Address</h3>
            <p>
              Uncheck Same as Service Address and in CCUI, enter the
              customer&apos;s current address.
            </p>
            <div>
              <div>
                <b>Street:</b> {priorAddress?.street}
              </div>
              <div>
                <b>Apartment:</b> {priorAddress?.unit}
              </div>
              <div>
                <b>City:</b> {priorAddress?.city}
              </div>
              <div>
                <b>State:</b> {priorAddress?.state}
              </div>
              <div>
                <b>Zip Code:</b>{' '}
                {priorAddress?.postalCode || priorAddress?.postal_code}
              </div>
            </div>
          </>
        )}

        <h3>Payment type</h3>
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={creditCheckMethod === 'autopay'}
                classes={{ disabled: classes.disabled }}
              />
            }
            label="Auto pay"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={creditCheckMethod === 'deposit'}
                classes={{ disabled: classes.disabled }}
              />
            }
            label="Deposit"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={creditCheckMethod === 'authorize-credit-check'}
                classes={{ disabled: classes.disabled }}
              />
            }
            label="Authorize Credit Check"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </div>
      </div>
      {ssnToken && (
        <div style={{ marginTop: 20 }}>
          <Button variant="contained" onClick={handleOpenSsnModal}>
            Show Full SSN Info
          </Button>
          <Modal
            open={openSsnModal}
            onClose={handleCloseSsnModal}
            title="Full SSN Info"
          >
            <div>
              <TokenexSSNDisplay token={ssnToken} record={record} />
            </div>
          </Modal>
        </div>
      )}
      <h3>Date of Birth</h3>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleOpenDobModal}>
          Show Date of Birth
        </Button>
        <Modal
          open={openDobModal}
          onClose={handleCloseDobModal}
          title="Date of Birth"
        >
          <div>
            <TokenexASCIIDisplay token={dobToken} record={record} />
          </div>
        </Modal>
      </div>
    </Fragment>
  )
}

const InstallationInformation = ({ classes, record }) => {
  const installationDate = record?.installation?.activation_date
  const installationType = record?.installation?.type ?? 'self'

  return (
    <Fragment>
      <div>
        <h3>Choose type of installation</h3>
        <FormControlLabel
          control={
            <Checkbox
              checked={installationType === 'self'}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="Self-install"
          labelPlacement="end"
          classes={{ label: classes.label }}
          disabled
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={installationType === 'professional'}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="Professional-install"
          labelPlacement="end"
          classes={{ label: classes.label }}
          disabled
        />
      </div>
      {installationDate && (
        <p>
          <b>Installation date -</b> {installationDate}
        </p>
      )}
      <div>
        <h3>Shipping type</h3>
        <p>Getting started kit, standard shipping fee up to $39.99</p>
        <p className={classes.subtext}>
          Proceed with ordering processing even if actual shipping cost is less
          than $39.99
        </p>
      </div>
    </Fragment>
  )
}

const BillingInfo = ({ price: { items = {} } = {}, record, classes }) => {
  const creditCheckMethod =
    record?.credit_check?.credit_check_method?.toLowerCase()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  if (!record?.payment?.credit_card) {
    return <NoPaymentMethodDisclaimer />
  }

  const paymentAmount =
    creditCheckMethod === 'autopay'
      ? items.autopay.price.toFixed(2)
      : creditCheckMethod === 'deposit'
      ? DEPOSIT_AMOUNT.toFixed(2)
      : ''
  const paymentKind =
    creditCheckMethod === 'autopay'
      ? 'pre-payment'
      : creditCheckMethod === 'deposit'
      ? 'deposit'
      : ''
  const paymentHeader = paymentKind
    ? `${paymentKind[0].toUpperCase()}${paymentKind.slice(1)}`
    : ''

  const {
    billing_address: billingAddress = {},
    expiration_date: expirationDate,
    first_name: firstName,
    last_name: lastName,
    pci = {}
  } = record?.payment?.credit_card || {}
  const creditCardProps = {
    ...pci,
    expirationDate,
    firstName,
    lastName
  }

  return (
    <Fragment>
      <div>
        <h3>{paymentHeader} Amount</h3>
        <div>${paymentAmount}</div>
      </div>
      <p className={classes.subtext}>
        Proceed with order processing if actual {paymentKind} amount is&nbsp;$
        {paymentAmount} or less.
      </p>
      <div>
        <h3>Credit Card Info</h3>
        <div>
          <Button variant="contained" onClick={handleOpen}>
            Show Credit Card Info
          </Button>
          <Modal open={open} onClose={handleClose} title="Credit Card Info">
            <CreditCard
              {...creditCardProps}
              allowTokenexDetokenizer={true}
              allowTokenDisplay={true}
              record={record}
            />
          </Modal>
        </div>
      </div>
      <div>
        <h3>Billing Address</h3>
        <div>
          <b>Street:</b> {billingAddress?.street}
        </div>
        <div>
          <b>Apartment:</b> {billingAddress?.unit}
        </div>
        <div>
          <b>City:</b> {billingAddress?.city}
        </div>
        <div>
          <b>State:</b> {billingAddress?.state}
        </div>
        <div>
          <b>Zip Code:</b>{' '}
          {billingAddress?.postalCode || billingAddress?.postal_code}
        </div>
      </div>
    </Fragment>
  )
}

const AdditionalOptions = ({ classes, record: { opt_ins = {} } = {} }) => (
  <Fragment>
    <div>
      <h3>Allow Updater and Xfinity to send me SMS updates about my order.</h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={opt_ins.sms_opt_in}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="Yes, I consent"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={!opt_ins.sms_opt_in}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="No, I do not consent"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>

      <h3>Enroll in Paperless Billing (EcoBill)</h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={opt_ins.sms_opt_in}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="Yes, I consent"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={!opt_ins.sms_opt_in}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="No, I do not consent"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
    </div>
  </Fragment>
)

export const OrderView = ({ record, classes }) => {
  const ssnToken = parseJson(record?.credit_check?.ssn?.token)?.token
  const dobToken = parseJson(record?.credit_check?.date_of_birth?.token)?.token
  const isTransferOrder = Boolean(record.installation?.service_transfer_dates)

  return (
    <Fragment>
      <Grid item xs={6}>
        <UserInfo record={record} />
      </Grid>
      <Grid item xs={6}>
        <PackageInfo record={record} />
      </Grid>
      {isTransferOrder ? (
        <TransferService
          ssnToken={ssnToken}
          record={record}
          classes={classes}
        />
      ) : (
        <>
          <Grid item xs={12}>
            <Card style={{ marginTop: 20 }}>
              <CardHeader title="Equipment Options" />
              <CardContent>
                <EquipmentOptions record={record} classes={classes} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card style={{ marginTop: 20 }}>
              <CardHeader title="Identity Verification" />
              <CardContent>
                <IdentityVerification
                  classes={classes}
                  dobToken={dobToken}
                  ssnToken={ssnToken}
                  record={record}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card style={{ marginTop: 20 }}>
              <CardHeader title="Installation Information" />
              <CardContent>
                <InstallationInformation record={record} classes={classes} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card style={{ marginTop: 20 }}>
              <CardHeader title="Billing info" />
              <CardContent>
                <BillingInfo
                  classes={classes}
                  record={record}
                  price={record.price}
                />
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Additional Options" />
          <CardContent>
            <AdditionalOptions record={record} classes={classes} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Activity record={record} classes={classes} />
      </Grid>
    </Fragment>
  )
}
