import React from 'react'

import { TokenexDetokenizer } from './tokenex/tokenexDetokenizer'

/**
 * Extracts and displays PII data from a given Token.
 *
 * @param {object} param0 - An object containing the token.
 */
// Might be useful https://docs.tokenex.com/reference/non-pci-configuration
export function TokenexASCIIDisplay({ token, record }) {
  if (record.status_reasons?.expired) return <>expired</>
  return (
    <>
      {/* https://docs.tokenex.com/docs/token-schemes#ssn */}
      <TokenexDetokenizer
        schema="ASCIITOKEN"
        mode="ASCIITOKEN"
        token={token}
        pci={false}
      />
    </>
  )
}
