import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Radio
} from '@material-ui/core'
import { parseJson } from '../../../../components/formatters/parseJson'
import { NoPaymentMethodDisclaimer } from '../../../fragments/no-payment-method'
import React, { Fragment, useState } from 'react'
import { CreditCard } from '../../../creditCard'
import { TokenexASCIIDisplay } from '../../../tokenexASCIIDisplay'
import { TokenexSSNDisplay } from '../../../tokenexSSNDisplay'
import Activity from '../activity'
import Modal from '../modal'
import PackageInfo from '../packageInfo'
import UserInfo from '../userInfo'

/* Equipment */
const Equipment = ({ classes, record }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <h3>Choose your rental equipment</h3>
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={
                  record?.installation?.internet_modem_choice === 'modemRental'
                }
                classes={{ disabled: classes.disabled }}
              />
            }
            label="Spectrum Modem Only"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={
                  record?.installation?.internet_modem_choice ===
                  'modemAndRouterRental'
                }
                classes={{ disabled: classes.disabled }}
              />
            }
            label="Spectrum Modem + Router (recommended)"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={
                  record.installation.internet_modem_choice === 'equipmentOwned'
                }
                classes={{ disabled: classes.disabled }}
              />
            }
            label="I'll use my own"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </div>
        {record?.price?.items?.xumo && (
          <>
            <h3>Streaming Box</h3>

            <div>
              <FormControlLabel
                control={
                  <Radio checked classes={{ disabled: classes.disabled }} />
                }
                label="Xumo Streaming Box"
                labelPlacement="end"
                classes={{ label: classes.label }}
                disabled
              />
            </div>
          </>
        )}
      </CardContent>
    </Card>
  </Fragment>
)

/* Credit Check-SSN-Contact */
const CreditCheckInfo = ({
  classes,
  serviceAddress,
  dobToken,
  ssnToken,
  currentAddressIsOverSixMonths,
  priorAddress,
  record
}) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [openDobModal, setOpenDobModal] = useState(false)
  const handleOpenDobModal = () => setOpenDobModal(true)
  const handleCloseDobModal = () => setOpenDobModal(false)

  return (
    <Fragment>
      <h3>Credit Check</h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="I authorize a credit check"
          labelPlacement="end"
          classes={{ label: classes.label }}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <h3>SSN</h3>
      </div>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleOpen}>
          Show Full SSN Info
        </Button>
        <Modal open={open} onClose={handleClose} title="Full SSN Info">
          <div>
            <TokenexSSNDisplay token={ssnToken} record={record} />
          </div>
        </Modal>
      </div>
      <h3>Have you lived for 6 months or longer at the address below?</h3>
      <div>
        <Address address={serviceAddress} />
      </div>
      <div>
        <FormControlLabel
          control={<Radio checked={currentAddressIsOverSixMonths} />}
          label="Yes"
          labelPlacement="end"
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={<Radio checked={!currentAddressIsOverSixMonths} />}
          label="No"
          labelPlacement="end"
          classes={{ label: classes.label }}
        />
      </div>
      <h3>Date of Birth</h3>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleOpenDobModal}>
          Show Date of Birth
        </Button>
        <Modal
          open={openDobModal}
          onClose={handleCloseDobModal}
          title="Date of Birth"
        >
          <div>
            <TokenexASCIIDisplay token={dobToken} record={record} />
          </div>
        </Modal>
      </div>
      {!currentAddressIsOverSixMonths && (
        <Fragment>
          <div>
            <h3>Prior Address</h3>
            <div>
              <b>Street:</b> {priorAddress?.address?.street}
            </div>
            <div>
              <b>Unit:</b> {priorAddress?.address?.unit}
            </div>
            <div>
              <b>City:</b> {priorAddress?.address?.city}
            </div>
            <div>
              <b>State:</b> {priorAddress?.address?.state}
            </div>
            <div>
              <b>Zip Code:</b>{' '}
              {priorAddress?.address?.postalCode ||
                priorAddress?.address?.postal_code}
            </div>
          </div>
        </Fragment>
      )}
      <p>Verification - Credit Results / Credit Options</p>
      <p>
        Contact consumer if they fail the credit results and will be required to
        pay a $50.00 deposit to go forward.
      </p>
    </Fragment>
  )
}

/* Installation */
const Installation = ({ classes, record }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <h3>Choose type of installation</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Self Install ($30.00)"
            labelPlacement="end"
            disabled
            classes={{ label: classes.label }}
          />
        </div>

        <div>
          <h3>Shipping type</h3>
          <p>Getting started kit, standard shipping fee up to $30.00</p>
          <p className={classes.subtext}>
            Proceed with ordering processing even if actual shipping cost is
            less than $30.00
          </p>
        </div>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Self Install ($30)"
            labelPlacement="end"
            disabled
            classes={{ label: classes.label }}
          />
        </div>

        <InstallationScheduling classes={classes} record={record} />
      </CardContent>
    </Card>
  </Fragment>
)

const InstallationScheduling = ({
  classes,
  record: { installation = {} } = {}
}) => (
  <Fragment>
    <h3>Customer&apos;s preferred installation date & times</h3>
    <p>
      Select the earliest date and time available within Spectrum installation
      schedule
    </p>
    <InstallationDate
      classes={classes}
      dateNumber={1}
      installation={installation}
    />
    <InstallationDate
      classes={classes}
      dateNumber={2}
      installation={installation}
    />
    <InstallationDate
      classes={classes}
      dateNumber={3}
      installation={installation}
    />
  </Fragment>
)

const InstallationDate = ({ dateNumber, installation }) => {
  // each date is its own property, including windows, so have to access dynamically
  const dateNumberStr = `date${dateNumber}`
  const date = installation[dateNumberStr]
  if (!date) return null
  return (
    <Fragment>
      <h4>Date {dateNumber}</h4>
      <p>Date: {new Date(date).toLocaleDateString()}</p>
    </Fragment>
  )
}

/* Additional Questions */
const OtherQuestions = ({ authorizedUser, price, classes }) => {
  const planPrice = (price?.plan || {}).price

  const monthlyItems = (Object.values(price?.items) || []).filter(
    item => item.billing_frequency === 'month'
  )
  const totalFees = monthlyItems
    .map(({ price }) => price)
    .reduce((sum, value) => sum + value, 0)

  const monthlyTotal = totalFees + planPrice

  return (
    <Fragment>
      <Card style={{ marginTop: 20 }}>
        <CardContent>
          <h3>Emergency Broadband Relief</h3>
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={true}
                  classes={{ disabled: classes.disabled }}
                />
              }
              label="No"
              labelPlacement="end"
              classes={{ label: classes.label }}
            />
          </div>
          <h3>Privacy Rights Opt Out</h3>
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={true}
                  classes={{ disabled: classes.disabled }}
                />
              }
              label="Yes"
              labelPlacement="end"
              classes={{ label: classes.label }}
            />
          </div>
          <h3>Spectrum Residential General Terms and conditions of Service</h3>
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={true}
                  classes={{ disabled: classes.disabled }}
                />
              }
              label="Yes"
              labelPlacement="end"
              classes={{ label: classes.label }}
            />
          </div>
          <h3>Privacy Policy for Charter Communications</h3>
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={true}
                  classes={{ disabled: classes.disabled }}
                />
              }
              label="Yes"
              labelPlacement="end"
              classes={{ label: classes.label }}
            />
          </div>
          <h3>Terms of Service and policies</h3>
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={true}
                  classes={{ disabled: classes.disabled }}
                />
              }
              label="Yes"
              labelPlacement="end"
              classes={{ label: classes.label }}
            />
          </div>
          <h3>California Consumer Privacy Policy</h3>
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={true}
                  classes={{ disabled: classes.disabled }}
                />
              }
              label="Yes"
              labelPlacement="end"
              classes={{ label: classes.label }}
            />
          </div>
          <h3>Authorized User</h3>
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={authorizedUser?.full_name?.length > 0}
                  classes={{ disabled: classes.disabled }}
                />
              }
              label="Yes"
              labelPlacement="end"
              classes={{ label: classes.label }}
            />
            {Boolean(authorizedUser?.full_name?.length > 0) && (
              <>
                <p>Authorized User Full Name: {authorizedUser?.full_name}</p>
                <p>Authorized Phone Number: {authorizedUser?.phone}</p>
              </>
            )}
          </div>
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={!Boolean(authorizedUser?.full_name?.length > 0)}
                  classes={{ disabled: classes.disabled }}
                />
              }
              label="No"
              labelPlacement="end"
              classes={{ label: classes.label }}
            />
          </div>
        </CardContent>
      </Card>
    </Fragment>
  )
}

const BillingInfo = ({ record }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  if (!record?.payment?.credit_card) {
    return <NoPaymentMethodDisclaimer />
  }

  const {
    billing_address: billingAddress = {},
    expiration_date: expirationDate,
    first_name: firstName,
    last_name: lastName,
    pci = {}
  } = record?.payment?.credit_card || {}
  const creditCardProps = {
    ...pci,
    expirationDate,
    firstName,
    lastName
  }

  return (
    <Fragment>
      <div>
        <h3>Credit Card Info</h3>
        <div>
          <Button variant="contained" onClick={handleOpen}>
            Show Credit Card Info
          </Button>
          <Modal open={open} onClose={handleClose} title="Credit Card Info">
            <CreditCard
              {...creditCardProps}
              allowTokenexDetokenizer={true}
              allowTokenDisplay={true}
              record={record}
            />
          </Modal>
        </div>
      </div>
      <div>
        <h3>Billing Address</h3>
        <div>
          <b>Street:</b> {billingAddress?.street}
        </div>
        <div>
          <b>Apartment:</b> {billingAddress?.unit}
        </div>
        <div>
          <b>City:</b> {billingAddress?.city}
        </div>
        <div>
          <b>State:</b> {billingAddress?.state}
        </div>
        <div>
          <b>Zip Code:</b>{' '}
          {billingAddress?.postalCode || billingAddress?.postal_code}
        </div>
      </div>
    </Fragment>
  )
}

const PromotionsOptions = ({ classes, record }) => {
  const creditCheckMethod =
    record?.credit_check?.credit_check_method?.toLowerCase()

  return (
    <Fragment>
      <h3>
        $5.00 off per month when signing up for Auto Bill Pay and Paperless
        Billing
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={creditCheckMethod === 'autopay'}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="I would like to enroll in Auto Pay Bills and Paperless billing and receive $5 off Internet per month"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={creditCheckMethod === 'manual'}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="I would like to pay manually"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
    </Fragment>
  )
}

const AdditionalOptions = ({ classes, record: { opt_ins = {} } = {} }) => (
  <Fragment>
    <h3>
      Allow Updater and Spectrum to reach you by phone or text if there’s a
      problem with my order?
    </h3>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={opt_ins.sms_opt_in}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="Yes, I consent"
        labelPlacement="end"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={!opt_ins.sms_opt_in}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="No, I do not consent"
        labelPlacement="end"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
  </Fragment>
)

const Address = ({ address }) => (
  <Fragment>
    <div>
      {address?.street} {Boolean(address?.apt) && ` Apt. ${address?.apt}`}
    </div>

    <div>
      {address?.city}, {address?.state} {address?.zip}
    </div>
  </Fragment>
)

/* Main constructed component */
export const OrderView = ({ record, classes }) => {
  const currentAddressIsOverSixMonths = Boolean(
    record.credit_check?.has_lived_at_current_address_for_more_than_6_months ==
      'Yes'
  )
  const priorAddress = record?.credit_check?.prior_address
  const ssnToken = parseJson(record?.credit_check?.ssn?.token)?.token
  const dobToken = parseJson(record?.credit_check?.date_of_birth?.token)?.token

  return (
    <Fragment>
      <Grid item xs={6}>
        <UserInfo record={record} />
      </Grid>
      <Grid item xs={6}>
        <PackageInfo record={record} />
      </Grid>

      <Grid item xs={12}>
        <Equipment classes={classes} record={record} />
      </Grid>

      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardContent>
            <CreditCheckInfo
              dobToken={dobToken}
              ssnToken={ssnToken}
              classes={classes}
              serviceAddress={record.move.to_address}
              currentAddressIsOverSixMonths={currentAddressIsOverSixMonths}
              priorAddress={priorAddress}
              record={record}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Promotions Options" />
          <CardContent>
            <PromotionsOptions classes={classes} record={record} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Billing info" />
          <CardContent>
            <BillingInfo record={record} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CardHeader title="Installation Information" />
        <Installation classes={classes} record={record} />
      </Grid>
      <Grid item xs={12}>
        <AdditionalOptions classes={classes} record={record} />
      </Grid>
      <Grid item xs={12}>
        <OtherQuestions
          price={record?.price}
          classes={classes}
          authorizedUser={
            record?.contact_information?.additional_authorized_user
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Activity record={record} classes={classes} />
      </Grid>
    </Fragment>
  )
}
