import { BaseProcessor } from './baseProcessor'

const endpoint = 'divisions'

const fields = {
  property_id: { required: true, key: 'id' },
  exclude_sure: {
    required: true,
    key: 'config.renters_insurance.exclude_sure'
  }
}

export default new BaseProcessor(endpoint, fields)
