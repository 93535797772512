import React from 'react'

import { TokenexDetokenizer } from './tokenex/tokenexDetokenizer'

/**
 * Extracts and displays PCI data from a given PCI Token.
 *
 * @param {object} param0 - An object containing the token.
 * @return {JSX.Element} The extracted PII data.
 */
// Might be useful https://docs.tokenex.com/reference/non-pci-configuration
export function TokenexSSNDisplay({ token, record }) {
  if (record.status_reasons?.expired) return <>expired</>
  return (
    <>
      {/* https://docs.tokenex.com/docs/token-schemes#ssn */}
      <TokenexDetokenizer schema="SSN" mode="SSN" token={token} pci={false} />
    </>
  )
}
