import { BaseProcessor } from './baseProcessor'

const endpoint = 'divisions'

const fields = {
  property_id: { required: true, key: 'id' },
  required: { required: true, key: 'config.renters_insurance.required' },
  description: { required: true, key: 'config.renters_insurance.description' },
  phone: { required: false, key: 'config.renters_insurance.phone' },
  cta_link: { required: false, key: 'config.renters_insurance.cta_url' },
  requirement_1: {
    required: false,
    key: 'config.renters_insurance.requirements',
    value: requirements
  },
  requirement_2: { required: false, excluded: true },
  requirement_3: { required: false, excluded: true },
  requirement_4: { required: false, excluded: true },
  insurance_name: { required: false, key: 'config.renters_insurance.name' },
  logo_url: { required: false, key: 'config.renters_insurance.logo_url' },
  external_property_id: {
    required: false,
    key: 'config.renters_insurance.external_property_id',
    value: value => String(value)
  },
  external_property_id_type: {
    required: false,
    key: 'config.renters_insurance.external_property_id_type'
  },
  jetty_enabled: {
    required: false,
    key: 'config.renters_insurance.jetty_enabled'
  },
  exclude_sure: {
    required: false,
    key: 'config.renters_insurance.exclude_sure'
  }
}

function requirements(value, row) {
  return [
    value,
    row['requirement_2'],
    row['requirement_3'],
    row['requirement_4']
  ].filter(Boolean)
}

export default new BaseProcessor(endpoint, fields)
