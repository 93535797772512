import React from 'react'
import creditCardType from 'credit-card-type'
import { TokenexDetokenizer } from './tokenex/tokenexDetokenizer'
import { Button, Box, Grid } from '@material-ui/core'
import { parseJson } from './formatters/parseJson'

/**
 * @param {import('./homeServicesOrder/types').DTSPayload['payment']['creditCard']} props
 */
export function CreditCard(props) {
  return props.tokenizer === 'tokenex' ? (
    <TokenexCreditCard {...props} />
  ) : (
    <ClassicCreditCardPayload {...props} />
  )
}

/**
 * @param {import('./homeServicesOrder/types').TokenexPayload['payment']['creditCard']} props
 */

export function TokenexCreditCard({
  record,
  token,
  expirationDate,
  firstName,
  lastName,
  allowTokenexDetokenizer = false,
  allowTokenDisplay = true
}) {
  if (record.status_reasons?.expired) return <>expired</>
  const [network] = creditCardType(token)
  const pciToken = parseJson(token)?.token || token

  return (
    <>
      {Boolean(allowTokenDisplay) && (
        <Box
          sx={{
            p: 2,
            border: '1px dashed grey',
            marginBottom: 10
          }}
        >
          <Grid container direction="row">
            <Grid item xs={8} style={{ marginTop: '10px' }}>
              PCI Token: <b>{pciToken}</b>
            </Grid>
            <Grid item xs={4}>
              <Button
                size="large"
                style={{ marginLeft: '10px', width: '110px' }}
                color="primary"
                variant="contained"
                onClick={() => {
                  navigator.clipboard.writeText(pciToken)
                }}
              >
                Copy
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {Boolean(allowTokenexDetokenizer) && (
        <>
          <Grid container direction="row">
            <Grid xs={4} item style={{ marginTop: '10px' }}>
              Card Number
            </Grid>
            <Grid xs={8} item style={{ marginTop: '10px' }}>
              <TokenexDetokenizer scheme="PCI" mode="PAN" token={pciToken} />
            </Grid>
            <Grid xs={4} item style={{ marginTop: '10px' }}>
              Card Type
            </Grid>
            <Grid xs={8} item style={{ marginTop: '10px' }}>
              {network ? network.niceType : 'Unknown, please contact support.'}
            </Grid>
            <Grid xs={4} item style={{ marginTop: '10px' }}>
              Expiration
            </Grid>
            <Grid xs={8} item style={{ marginTop: '10px' }}>
              {expirationDate}
            </Grid>
            <Grid xs={4} item style={{ marginTop: '10px' }}>
              CVV
            </Grid>
            <Grid xs={8} item style={{ marginTop: '10px' }}>
              <TokenexDetokenizer scheme="PCI" mode="CVV" token={pciToken} />
            </Grid>
            <Grid xs={4} item style={{ marginTop: '10px' }}>
              Name on Card:
            </Grid>
            <Grid xs={8} item style={{ marginTop: '10px' }}>
              {firstName} {lastName}
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

/**
 * @param {import('./homeServicesOrder/types').ClassicPayload['payment']['creditCard']} props
 */
function ClassicCreditCardPayload(props) {
  const [network] = creditCardType(props.number)
  return (
    <>
      <div>Card Number: {props.number}</div>
      <div>Card Type: {network ? network.niceType : 'unknown'}</div>
      <div>Expiration: {props.expirationDate}</div>
      <div>CVV: {props.securityCode}</div>
      <div>
        Name on Card: {props.firstName}&nbsp;
        {props.lastName}
      </div>
    </>
  )
}
